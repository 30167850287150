@use '@on3/styles/variables' as var;

@use '@on3/styles/mixins' as mix;

@include mix.homelayout;

.metaIcon {
  height: 36px;
  width: 80px;
}
